<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="Выберите" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2> Соглашение об открытии магазина "UniLive" </h2>
		<p> Соглашение об открытии магазина UniLive - это Pix Technology PTE. LTD.(далее именуемый "Наша сторона") и Ваше (вы являетесь физическим лицом, юридическим лицом или другой
			организацией) электронное соглашение, имеющее юридическую силу и регулирующее права и обязательства сторон, далее
			именуемое "Настоящее Соглашение". Ваша галочка « Я согласен » или нажатие кнопки « Я прочитал и выполнил это
			соглашение» означает, что вы внимательно прочитали, полностью поняли и полностью приняли все условия настоящего
			Соглашения без каких - либо оговорок.
		</p>
		<h3> Цель первого протокола </h3>
		<p> Настоящее соглашение направлено на уточнение прав и обязанностей между продавцами и платформой UniLive,
			регулирование деловой практики продавцов, защиту прав потребителей, содействие здоровому развитию платформы
			UniLive и обеспечение законных прав и интересов сторон в сделках.
		</p>
		<h3> Второе определение </h3>
		<p> 1, UniLive - это платформа, предоставляющая поддержку онлайн - транзакций и услуг для операторов данного
			приложения и связанных с ним услуг. </p>
		<p>
			Торговцы - это физические или юридические лица, зарегистрированные и осуществляющие коммерческую деятельность в
			UniLive, включая, но не ограничиваясь ими, индивидуальных предпринимателей, компании и другие юридические лица.
		</p>
		<p>
			Потребитель - пользователь, который покупает товары или услуги в UniLive.
		</p>
		<h3> Статья 3 Присутствие продавца </h3>
		<p>
			Требования к регистрации: Торговцы должны заполнить в UniLive достоверную и точную личную или корпоративную
			информацию, включая имя, контактную информацию, лицензию на предпринимательскую деятельность, свидетельство о
			налоговой регистрации и т.д. Предприниматели должны обеспечить своевременное обновление регистрационной
			информации, если она изменяется, в течение 48 часов.
		</p>
		<p> 2, Процесс аудита: UniLive будет проверять информацию, представленную продавцом, обычно в течение 3 - 5 рабочих
			дней. После прохождения аудита продавец получит уведомление о подтверждении, функция открытия магазина официально
			включена.
		</p>
		<p> 3, Настройка магазина: продавцы должны установить магазин на UniLive, включая название магазина, логотип,
			профиль, классификацию товаров и т. Д. Чтобы убедиться, что информация соответствует требованиям платформы.
		</p>
		<h3> Статья 4 Права и обязанности продавца </h3>
		<p>
			Публикуйте информацию о товарах и продавайте их в UniLive, пользуясь услугами, предоставляемыми Платформой.
		</p>
		<p> 2, участие в рекламных кампаниях и продвижении на рынке UniLive, повышение экспозиции. </p>
		<p> 3, получать данные о продажах, отзывы пользователей и отчеты об анализе рынка для оптимизации бизнес -
			стратегии. </p>
		<p> 4, соответствие товарам: обеспечение законности, подлинности и соответствия продаваемых товаров, продажа
			контрафактной и дрянной продукции не допускается. </p>
		<p> 5, Обновление информации: регулярно обновлять информацию о товарах, чтобы убедиться, что информация о ценах,
			запасах, распределении является точной и ясной. </p>
		<p> 6, Соблюдение правил Платформы: Соблюдение различных правил управления и эксплуатационных спецификаций UniLive,
			участие в обучении Платформы, повышение деловых возможностей. </p>
		<h3> Статья 5 Права и обязанности UniLive </h3>
		<p> 1 Проверка и управление информацией, публикуемой продавцом, для обеспечения соответствия информации. </p>
		<p> 2 Принимать соответствующие меры в отношении торговцев, нарушающих соглашение, включая, но не ограничиваясь
			этим, предупреждение, приостановление или прекращение работы их магазина до судебного разбирательства. </p>
		<p> 3, в соответствии с рыночными условиями и изменениями в законодательстве и нормативных актах, регулярно
			обновлять политику платформы, продавцы должны быть в курсе и соблюдать. </p>
		<p> 4 Обеспечить безопасную и стабильную торговую платформу для обеспечения безопасности торговых операций для
			продавцов и потребителей, предотвращения мошенничества и других нарушений. </p>
		<p> 5, Своевременная обработка вопросов и отзывов бизнеса, обеспечение необходимой технической поддержки и обучения.
		</p>
		<p> 6, регулярно предоставлять предприятиям анализ бизнес - данных, отчеты о рыночных тенденциях и рекомендации по
			продвижению, чтобы помочь предприятиям улучшить производительность. </p>
		<h3> Статья 6 Ответственность за нарушение </h3>
		<p>
			Если продавец нарушает какое - либо из положений настоящего Соглашения, UniLive имеет право потребовать от
			продавца внести исправления и, в зависимости от тяжести обстоятельств, принять соответствующие меры наказания,
			включая, но не ограничиваясь этим, снижение класса магазина, ограничение доступа к товарам, прекращение
			обслуживания и т.д.
		</p>
		<p> 2, жалоба потребителя, вызванная коммерческими причинами, торговец должен нести соответствующую юридическую
			ответственность и ответственность. </p>
		<p> 3 Если UniLive не предоставляет услуги в соответствии с соглашением, продавец может потребовать от UniLive
			возмещения убытков в размере, определенном по согласованию сторон. </p>
		<h3> Изменение и расторжение Соглашения Статья 7 </h3>
		<p> 1 Любые изменения или изменения в настоящем Соглашении могут быть отменены только по взаимному согласию сторон
		</p>
		<p> 2, UniLive имеет право периодически пересматривать соглашение в соответствии с законами и правилами, изменениями
			рынка и другими факторами и своевременно уведомлять продавца. </p>
		<p> 3 Если продавец желает расторгнуть настоящее Соглашение, он должен уведомить UniLive в письменной форме за 7
			дней до этого и надлежащим образом обработать незавершенные заказы и жалобы потребителей до расторжения. </p>
		<p> 4, UniLive имеет право расторгнуть настоящее Соглашение без предварительного уведомления в случае обнаружения
			серьезного нарушения со стороны продавца. </p>
		<h3> Статья 8 Другие статьи </h3>
		<p> 1 Положения о конфиденциальности: Стороны обеспечивают конфиденциальность коммерческой тайны и другой
			информации, известной им в ходе выполнения соглашения, и не разглашают ее третьим сторонам.
		</p>
		<p> 2, форс - мажор: если из - за форс - мажорных факторов (например, стихийного бедствия, войны, изменения политики
			и т.д.) исполнение соглашения становится невозможным, стороны не несут ответственности за нарушение договора.
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/StoreOpeningAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/StoreOpeningAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/StoreOpeningAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/StoreOpeningAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/StoreOpeningAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/StoreOpeningAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/StoreOpeningAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/StoreOpeningAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/StoreOpeningAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/StoreOpeningAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/StoreOpeningAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/StoreOpeningAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/StoreOpeningAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/StoreOpeningAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>